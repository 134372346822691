import React, { useState } from "react";
import styles from "./Filter.module.scss";
import GlobalStrings from "utils/globalStrings";
import { ReactSVG } from "react-svg";
import { UseFormReturn } from "react-hook-form";
import { Inputs } from "utils/types";
import classNames from "classnames";
import { FilterType } from "utils/enums";
import { FilterPopupContent, TooltipPopup } from "components";

const Filter = (props: {
  label: string;
  icon: string;
  name: FilterType;
  isSearchable: boolean;
  type: "grid" | "linear";
  options: {
    name: string;
    value: string;
    image?: string;
  }[];
  form: UseFormReturn<Inputs, any, any>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TooltipPopup
      isOpen={isOpen}
      position="right top"
      content={
        <FilterPopupContent
          type={props.type}
          options={props.options}
          form={props.form}
          name={props.name}
          isSearchable={props.isSearchable}
          closePopup={setIsOpen}
        />
      }
      trigger={
        <div className={styles.filter}>
          <ReactSVG src={props.icon} className={styles.image} />

          <div
            className={classNames(styles.text, {
              [styles.active]: props.form.watch(props.name),
            })}
            onClick={() => setIsOpen(true)}
            aria-hidden="true"
          >
            <p className={styles.label}>{props.label}</p>
            <p
              className={classNames(styles.value, {
                [styles.error]: props.form.formState.errors?.[props.name]?.message,
              })}
            >
              {props.form.watch(props.name)?.name ??
                props.form.formState.errors?.[props.name]?.message ??
                GlobalStrings["Select an option"]}
            </p>
          </div>
        </div>
      }
    />
  );
};

export default Filter;
