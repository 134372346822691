import React, {useState, useEffect} from "react";
import GlobalStrings from "utils/globalStrings";
import { useNavigate } from "react-router-dom";

import styles from "./Header.module.scss";
import classNames from "classnames";
import cokeLogo from "assets/images/coke-logo.svg";
import studioXLogo from "assets/images/Prod-X_Red.png";
import {useOktaAuth} from '@okta/okta-react';
import {UserClaims} from "@okta/okta-auth-js";
import {deleteFromLocalStorage, getFromLocalStorage} from "../../utils/globalFunctions";


interface HeaderProps {
    setCorsErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    customAuthHandler: () => Promise<void>
}

const Header = ({ setCorsErrorModalOpen, customAuthHandler }: HeaderProps) => {
    const {authState, oktaAuth} = useOktaAuth();
    const [userInfo, setUserInfo] = useState<UserClaims | null>(null);
    const user = getFromLocalStorage("user");
    const navigate = useNavigate();
    const userName = user?.user?.name.split(".").join(" ");


    const isCorsError = (err: any) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');
    useEffect(() => {
        if (!authState) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]);
    const logout = async () => {
        const basename = process.env.REACT_APP_BASE_URL;
        try {
            await oktaAuth.signOut({postLogoutRedirectUri: basename});
        } catch (err) {
            if (isCorsError(err)) {
                setCorsErrorModalOpen(true);
            } else {
                throw err;
            }
        }
    };

    if (!authState) {
        return (
            <div>Loading...</div>
        );
    }

    const logoutHandler = () => {
        deleteFromLocalStorage("user");
        navigate("/");
    }

    return (
        <div
            className={classNames(styles.header, {
                [styles.signin_page]: location.pathname === "/",
            })}
        >
            <div className={styles.logo}>
                <img src={studioXLogo} alt={GlobalStrings["STUDIO X"]}/>
            </div>
            {authState.isAuthenticated && userInfo && (
                <div className={styles.user_details}>
                    <p className={styles.user_name}>
                        {GlobalStrings.Welcome}, {userInfo.name}
                    </p>
                    <div className={styles.user_icon}>
                        <img src={"/assets/user-icon.png"} alt="user icon"/>
                    </div>
                    <button id="logout-button" onClick={logout} className={styles.logout}  onKeyDown={logout}>Logout</button>
                </div>
            )
            }
            {user && (
                <div className={styles.user_details}>
                    <p className={styles.user_name}>
                        {GlobalStrings.Welcome}, {userName}
                    </p>
                    <div className={styles.user_icon}>
                        <img src={"/assets/user-icon.png"} alt="user icon"/>
                    </div>
                    <button id="logout-button" onClick={logoutHandler} className={styles.logout}  onKeyDown={logout}>Logout</button>
                </div>
            )
            }
            {!authState.isAuthenticated && !userInfo && !user && (
                <div className={styles.user_details}>
                    <button id="login-button" onClick={customAuthHandler} onKeyDown={customAuthHandler} className={styles.logout}>{location.pathname === "/" ? '':'Login'}</button>
                </div>
            )
            }
            {
                location.pathname === "/" && (
                    <div>
                        <img src={cokeLogo} alt="coke logo"/>
                    </div>
                )
            }
        </div>
    );
};

export default Header;
