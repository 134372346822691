import {useOktaAuth} from '@okta/okta-react';
import React, {useState, useEffect} from "react";
import {Navigate} from "react-router-dom";
import { getFromLocalStorage } from "utils/globalFunctions";
import { SigninForm } from "components";
import { UserClaims } from "@okta/okta-auth-js";

const Public = () => {
    const {authState, oktaAuth} = useOktaAuth();
    const [userInfo, setUserInfo] = useState<UserClaims|null>(null)
    const user = getFromLocalStorage("user");
    useEffect(() => {
        if (!authState) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((userInfo) => {
                setUserInfo(userInfo);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes


    if (!authState) {
        return (
            <div>Loading...</div>
        );
    }
    if (authState.isAuthenticated && userInfo || user) {
        return <Navigate to="/dashboard" />;
    } else
        return (
            <section>
                <SigninForm/>
            </section>
        );
};
export default Public;
