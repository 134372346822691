import React, {useEffect, useState} from "react";
import {useOktaAuth} from '@okta/okta-react';
import styles from "./SigninForm.module.scss";
import GlobalStrings from "utils/globalStrings";
import parse from "html-react-parser";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useRequest from "hooks/useRequest";
import { setToLocalStorage } from "utils/globalFunctions";
import {Button} from 'semantic-ui-react';
import { UserClaims } from "@okta/okta-auth-js";

type SigninInput = {
  email: string;
  password: string;
};

const SigninForm = () => {
  const {authState, oktaAuth} = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims|null>(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninInput>();
  const navigate = useNavigate();

  const { request: login } = useRequest({
    url: "/auth/sign-in",
    method: "post",
    hideErrorMessage: true,
    onSuccess: (response: any) => {
      setToLocalStorage("user", response.data);
      navigate("/dashboard");
    },
  });

  const onSubmit: SubmitHandler<SigninInput> = (data) => {
    login({ body: { email: data.email, password: data.password } });
  };

  useEffect(() => {
    if (!authState) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((userInfo) => {
        setUserInfo(userInfo);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth, userInfo]); // Update if authState changes

  const loginOkta = async () => oktaAuth.signInWithRedirect();

  return (
    <div className={styles.signin}>
      <div className={styles.signin_container}>
        <h1>{parse(GlobalStrings["Let's create some magic"])}</h1>
        <p>{GlobalStrings["Sign in"]}</p>
        {(!process.env.REACT_APP_IS_PROD || process.env.REACT_APP_IS_PROD === 'false') &&
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_group}>
            <input
                type="text"
                {...register("email", {
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/,
                    message: GlobalStrings["Invalid email"],
                  },
                  required: GlobalStrings["Required field"],
                })}
                placeholder={GlobalStrings["example.name@organization.com"]}
            />

            <p className={styles.error}>{errors.email?.message && errors.email.message}</p>
            <input
                type="password"
                {...register("password", {
                  required: GlobalStrings["Required field"],
                })}
                placeholder="password"
            />

            <p className={styles.error}>{errors.password?.message && errors.password.message}</p>
          </div>

          <div className={styles.form_group}>
            <input type="submit" value={GlobalStrings.login}/>
          </div>
        </form>
        }
        <div className={styles.form_group}>
          <Button id="login-button" primary onClick={loginOkta} className={styles.button}>Login
            with OKTA</Button>
        </div>
      </div>
    </div>
  );
};

export default SigninForm;
