import React, { useCallback } from "react";
import styles from "./AnimatedButton.module.scss";
import GlobalStrings from "utils/globalStrings";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { Engine } from "tsparticles-engine";

const AnimatedButton = (props: { onSubmit: any; errors: any }) => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <div className={styles.button_container}>
      <button type="submit" onClick={props.onSubmit} disabled={Object.keys(props.errors).length > 0} id="submit_button">
        {GlobalStrings["Create Magic"]}

        <Particles
          id="tsparticles"
          className={styles.particles}
          init={particlesInit}
          options={{
            background: {
              color: {
                value: "transparent",
              },
            },
            fpsLimit: 120,
            particles: {
              color: {
                value: "#F40000",
              },
              move: {
                direction: "outside",
                enable: true,
                outModes: {
                  default: "out",
                },
                random: false,
                speed: 2,
                straight: true,
              },
              number: {
                density: {
                  enable: true,
                  area: 170,
                },
                value: 120,
              },
              opacity: {
                value: 1,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 10 },
              },
            },
            detectRetina: true,
            fullScreen: {
              enable: false,
            },
          }}
        />
      </button>
    </div>
  );
};

export default AnimatedButton;
