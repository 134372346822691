import React from "react";
import shareIcon from "assets/icons/share-icon.svg";
import downloadIcon from "assets/icons/download-icon.svg";
import styles from "./Button.module.scss";
import { ReactSVG } from "react-svg";

const Button = (props: {
	text: string;
	icon: "download" | "share";
	action: Function;
}) => {
	return (
		<button
			className={styles.button}
			onClick={() => props.action()}
		>
			<ReactSVG
				src={props.icon === "share" ? shareIcon : downloadIcon}
				className={styles.icon}
			/>

			<p>{props.text}</p>
		</button>
	);
};

export default Button;
