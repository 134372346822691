import React, { useEffect, useState } from "react";
import styles from "./CameraSettings.module.scss";
import classNames from "classnames";
import { Filters } from "components/RightSidebar/RightSidebar";
import GlobalStrings from "utils/globalStrings";
import ReactSlider from "react-slider";
import { data } from "utils/data";

const CameraSettings = (props: {
  filters: Filters | undefined;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | Function;
}) => {
  const [defocusDistance, setDefocusDistance] = useState(props.filters?.cameraSettings?.defocusDistance);
  const [fstop, setFstop] = useState(props.filters?.cameraSettings?.fstop);
  const [HDRIRotation, setHDRIRotation] = useState(props.filters?.cameraSettings?.HDRIRotation);

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          cameraSettings: {
            ...prev?.cameraSettings,
            defocusDistance,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [defocusDistance]);

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          cameraSettings: {
            ...prev?.cameraSettings,
            fstop,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [fstop]);

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          cameraSettings: {
            ...prev?.cameraSettings,
            HDRIRotation,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [HDRIRotation]);

  const resetHandler = () => {
    setDefocusDistance(data.defaultValues.cameraSettings.defocusDistance);
    setFstop(data.defaultValues.cameraSettings.fstop);
    setHDRIRotation(data.defaultValues.cameraSettings.HDRIRotation);

    props.setFilters((prev: any) => {
      return {
        ...prev,
        cameraSettings: {
          angle: data.defaultValues.cameraSettings.angle,
          defocusDistance: data.defaultValues.cameraSettings.defocusDistance,
          fstop: data.defaultValues.cameraSettings.fstop,
          HDRIRotation: data.defaultValues.cameraSettings.HDRIRotation,
        },
      };
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <p>{GlobalStrings.Angle}</p>
        <div className={styles.grid_container}>
          {props.filters?.cameraSettings.angleFilters.map((item) => {
            return (
              <div
                key={item.id}
                className={classNames("filter_with_image", styles.item, {
                  [styles.active]: props?.filters?.cameraSettings?.angle === item.value,
                  [styles.disabled]: !item.enabled,
                })}
                onClick={() => {
                  if (item.enabled) {
                    props.setFilters((prev: any) => {
                      return {
                        ...prev,
                        cameraSettings: {
                          ...prev?.cameraSettings,
                          angle: item.value,
                        },
                      };
                    });
                  }
                }}
                aria-hidden="true"
              >
                <img src={item.image} className="image" alt={item.value} />
                <p>{item.value}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.inner_container}>
        <p>{GlobalStrings.Defocus}</p>
        <div className={styles.defocus}>
          <ReactSlider
            className={styles.slider}
            thumbClassName={styles.thumb}
            thumbActiveClassName={styles.active_thumb}
            trackClassName={styles.track}
            markClassName={styles.mark}
            renderThumb={(props, state) => (
              <div {...props}>
                <div className={styles.current_value}>{state.valueNow}</div>
              </div>
            )}
            onChange={(newValue) => setDefocusDistance(newValue)}
            value={defocusDistance ?? data.defaultValues.cameraSettings.defocusDistance}
            min={0}
            max={100}
            marks={[0, 108]}
            step={0.1}
            renderMark={(props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 8 : order}</span>;
            }}
          />
        </div>
      </div>
      <div className={styles.inner_container}>
        <p>{GlobalStrings.FStop}</p>
        <div className={styles.defocus}>
          <ReactSlider
            className={styles.slider}
            thumbClassName={styles.thumb}
            thumbActiveClassName={styles.active_thumb}
            trackClassName={styles.track}
            markClassName={styles.mark}
            renderThumb={(props, state) => (
              <div {...props}>
                <div className={styles.current_value}>{state.valueNow}</div>
              </div>
            )}
            onChange={(newValue) => setFstop(newValue)}
            value={fstop ?? data.defaultValues.cameraSettings.fstop}
            min={1.7}
            max={30}
            marks={[1.7, 32]}
            step={0.1}
            renderMark={(props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 1.7 ? +order - 2 : order}</span>;
            }}
          />
        </div>
      </div>
      <div className={styles.inner_container}>
        <p>{GlobalStrings["HDRI Rotation"]}</p>
        <div className={styles.rotation}>
          <div className={styles.current_value}>{HDRIRotation ?? 0}°</div>
          <ReactSlider
            className={styles.slider}
            thumbClassName={styles.thumb}
            thumbActiveClassName={styles.active_thumb}
            trackClassName={styles.track}
            markClassName={styles.mark}
            renderThumb={(props) => (
              <div {...props}>
                <span className={classNames(styles.left, styles.arrow)}></span>
                <span className={styles.bullet}></span>
                <span className={classNames(styles.right, styles.arrow)}></span>
              </div>
            )}
            onChange={(newValue) => setHDRIRotation(newValue)}
            value={HDRIRotation ?? 0}
            min={0}
            max={360}
            marks={[0, 220, 400]}
            renderMark={(props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 40 : order}°</span>;
            }}
          />
        </div>
      </div>
      <div className={styles.buttons_container}>
        <button className={styles.btn} onClick={resetHandler}>
          {GlobalStrings.Reset}
        </button>
        <button className={styles.btn} onClick={() => props.setIsOpen(false)}>
          {GlobalStrings.OK}
        </button>
      </div>
    </div>
  );
};

export default CameraSettings;
