import React from "react";

import styles from "./Dashboard.module.scss";
import { Link } from "react-router-dom";
import GlobalStrings from "utils/globalStrings";
import parse from "html-react-parser";


const Dashboard = () => {
  return (
      <section className={styles.dashboard_container}>
        <h1>{GlobalStrings["My Magic"]}</h1>
        <Link to="/asset-generation">
          <button>{parse(GlobalStrings["Generate New Asset"])}</button>
        </Link>
      </section>

  );
};
export default Dashboard;
