const GlobalStrings = {
  "Required field": "Required field",
  "STUDIO X": "STUDIO X",
  Welcome: "Welcome",
  "Generate New Asset": "Generate<br/>New<br/>Asset",
  "My Magic": "My Magic",
  footerText: "© 2024 The Coca-Cola Company. All rights reserved",
  Product: "Product",
  Label: "Label",
  "Select a Product": "Select a Product",
  "Select a Label": "Select a Label",
  Region: "Region",
  "Select Location": "Select Location",
  "Meal Type": "Meal Type",
  "Select a Meal type": "Select a Meal type",
  Prompt: "Prompt",
  "Second Prompt": "Second Prompt",
  "Prompt Style": "Prompt Style",
  "Describe what you want to see": "Describe what you want to see",
  "of 2000 Characters": " of 2000 Characters",
  "Describe what you do not want to see": "Describe what you do not want to see",
  "Negative Prompt": "Negative Prompt",
  "Advanced Filters": "Advanced Filters",
  Resolution: "Resolution",
  "No. of Images": "No. of Images",
  "Enable Droplets": "Enable Droplets",
  "Create Magic": "Create Magic",
  "Share Image To": "Share Image To",
  ChiliPublish: "ChiliPublish",
  Back: "Back",
  "No values": "No values",
  "Let's create some magic": "Let&apos;s create some <span>magic.</span>",
  "Sign in": "Sign in",
  "Invalid email": "Invalid email",
  SSO: "SSO",
  login: "login",
  "example.name@organization.com": "example.name@organization.com",
  "AI Model": "AI Model",
  "Select AI Type": "Select a AI model",
  "Select an option": "Select an option",
  Load: "Load",
  Emphasis: "Emphasis",
  "Describe the specific features of your visual": "Describe the specific features of your visual",
  Model: "Model",
  promptsValidation: "Please use only alphabets, numbers or specific special characters (,.-|'\").",
  maxLength2000: "Max limit reached.",
  Search: "Search",
  "Please select a region": "Please select a region",
  "No options": "No options",
  Filters: "Filters",
  Logout: "Logout",
  "Set Dressing": "Set Dressing",
  "Camera Settings": "Camera Settings",
  "Light Settings": "Light Settings",
  Droplets: "Droplets",
  Sparse: "Sparse",
  Medium: "Medium",
  Mid: "Mid",
  Busy: "Busy",
  Dense:"Dense",
  Cancel: "Cancel",
  Continue: "Continue",
  Export: "Export",
  Low: "Low",
  High: "High",
  Reset: "Reset",
  "Are you sure you want to download your image?":"Are you sure you want to download your image?",
  OK: "OK",
  "HDRI Rotation": "HDRI Rotation",
  "Low Resolution":"Low Resolution",
  "High Resolution":"High Resolution",
  "We are busy upscaling your image, this will take approx 1-2 minutes. Please don't close the window!":"We are busy upscaling your image, this will take approx 1-2 minutes. Please don't close the window!",
  "Downloaded image(s) will appear in your downloads folder when complete.":"Downloaded image(s) will appear in your downloads folder when complete.",
  Defocus: "Defocus distance",
  FStop: "FStop",
  Angle: "Angle",
  Intensity: "Intensity",
  Temperature: "Temperature",
  "Change HDRI": "Change HDRI",
  "Interior Warm": "Interior Warm",
  "Interior Cool": "Interior Cool",
  "Exterior Warm": "Exterior Warm",
  "Exterior Cool": "Exterior Cool",
  "Key Light Height": "Key Light Height",
  "Key Light Rotation": "Key Light Rotation",
  "Key Light Intensity":"Key Light Intensity",
  "HDRI Light Intensity":"HDRI Light Intensity",
  "Key Light":"Key Light",
  "HDRI Lighting":"HDRI Lighting",
  "Tools":"Tools",
  "Please select a product":"Please select a product"
};

export default GlobalStrings;
