import React, { useEffect, useState } from "react";
import styles from "./Filters.module.scss";
import productIcon from "assets/icons/filters/product_icon.svg";
import mealTypeIcon from "assets/icons/filters/meal_type_icon.svg";
import regionIcon from "assets/icons/filters/location_icon.svg";
import AIModelIcon from "assets/icons/filters/ai_model_icon.svg";
import product1 from "assets/products/Glass Bottle.png";
import product2 from "assets/products/can.png";
import Filter from "components/Filter/Filter";
import { UseFormReturn } from "react-hook-form";
import { Inputs } from "utils/types";
import useRequest from "hooks/useRequest";
import { AI } from "utils/enums";
import GlobalStrings from "utils/globalStrings";
import Loader from "components/Loader/Loader";

const Filters = (props: {
  form: UseFormReturn<Inputs, any, any>;
  setStabilityPromptData: React.Dispatch<
    React.SetStateAction<
      | {
          sdxl_postfix: string;
          sdxl_negative_prompt: string;
        }
      | undefined
    >
  >;
}) => {
  const [data, setData] = useState();
  const { request: getPrompts, pending } = useRequest({
    url: "/omniverse/prompts",
    method: "get",
    onSuccess: (response) => {
      setData(response.data);
      props.setStabilityPromptData(response.data?.["Prompt Data"] as any);
    },
  });

  useEffect(() => {
    getPrompts();
  }, []);

  const labels = data?.["Product Data"]["Product type"]?.[props.form.watch("product")?.value] as any;

  const filters = [
    {
      label: GlobalStrings.Product,
      icon: productIcon,
      name: "product",
      type: "grid",
      isSearchable: false,
      options: Object.keys(data?.["Product Data"]["Product type"] ?? []).map((item) => {
        return {
          value: item,
          name: item,
          image: item === "Glass Bottle" ? product1 : item === "Slim Can" ? product2 : "",
        };
      }),
    },
    {
      label: GlobalStrings.Label,
      icon: productIcon,
      name: "label",
      type: "linear",
      isSearchable: false,
      options: labels?.Labels?.map((item: string) => {
        return {
          value: item,
          name: item,
        };
      }),
    },
    {
      label: GlobalStrings.Region,
      icon: regionIcon,
      name: "region",
      type: "linear",
      isSearchable: true,
      options: Object.keys(data?.["Region Data"] ?? []).map((item) => {
        return {
          value: item,
          name: item,
        };
      }),
    },
    {
      label: GlobalStrings["Meal Type"],
      icon: mealTypeIcon,
      name: "mealType",
      type: "linear",
      isSearchable: false,
      options: Object.keys(data?.["Region Data"][props.form.watch("region")?.value] ?? []).map((item) => {
        return {
          value: item,
          name: item,
        };
      }),
    },

    {
      label: GlobalStrings["AI Model"],
      icon: AIModelIcon,
      name: "AIType",
      type: "linear",
      isSearchable: false,
      options: [
        {
          name: "Stability AI",
          value: AI.STABILITY,
        },
      ],
    },
  ];

  useEffect(() => {
    props.form.setValue("mealType", undefined);
    props.form.setValue("prompt", "");
    props.form.setValue("negativePrompt", "");
    props.form.setValue("secondPrompt", "");
  }, [props.form.watch("region")?.name]);

  useEffect(() => {
    const region = data?.["Region Data"]?.[props.form.watch("region")?.value];
    const mealType: any = region?.[props.form.watch("mealType")?.value ?? ""];

    if (region !== undefined && mealType !== undefined) {
      if (props.form.watch("AIType")?.value === AI.STABILITY) {
        props.form.setValue("prompt", mealType?.prompt);
        props.form.setValue("secondPrompt", mealType?.sdxl_detailer);
      } else {
        props.form.setValue("prompt", "");
      }
      props.form.setValue("negativePrompt", mealType?.negative_prompt);
      props.form.setValue("map", mealType);
      props.form.clearErrors("prompt");
      props.form.clearErrors("negativePrompt");
    }
  }, [props.form.watch("mealType"), props.form.watch("AIType")]);

  return (
    <div className={styles.filters}>
      <p>{GlobalStrings.Filters}</p>

      <div className={styles.filters_container}>
        {filters.map((item) => {
          return (
            <Filter
              key={item.name}
              label={item.label}
              icon={item.icon}
              name={item.name as any}
              type={item.type as "grid" | "linear"}
              isSearchable={item.isSearchable}
              options={item.options}
              form={props.form}
            />
          );
        })}
      </div>
      {pending && <Loader />}
    </div>
  );
};

export default Filters;
