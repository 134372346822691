import React, { useEffect, useId, useState } from "react";
import styles from "./FilterPopupContent.module.scss";
import { Inputs } from "utils/types";
import { UseFormReturn } from "react-hook-form";
import classNames from "classnames";
import SearchInput from "components/SearchInput/SearchInput";
import { FilterType } from "utils/enums";
import InfoMessage from "components/InfoMessage/InfoMessage";
import GlobalStrings from "utils/globalStrings";

const FilterPopupContent = (props: {
  form: UseFormReturn<Inputs, any, any>;
  type: "grid" | "linear";
  isSearchable?: boolean;
  name: FilterType;
  options: {
    name: string;
    value: string;
    image?: string;
  }[];
  closePopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const id = useId();
  const [filteredOptions, setFilteredOptions] = useState(props.options);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (searchValue) {
      setFilteredOptions(props.options.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase())));
    } else {
      setFilteredOptions(props.options);
    }
  }, [searchValue]);

  return (
    <div className={styles.popup}>
      <div className={styles.popup_inner}>
        <div>
          {props.isSearchable && (
            <SearchInput
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              disabled={!filteredOptions?.length}
            />
          )}
          {
            <div
              className={classNames({
                [styles.grid]: props.type === "grid",
                [styles.linear]: props.type === "linear",
              })}
            >
              {filteredOptions?.map((item) => {
                return (
                  <div
                    key={item.name + id}
                    className={classNames(styles.item, {
                      [styles.active]: props.form.watch(props.name)?.value === item.name,
                    })}
                    onClick={() => {
                      props.form.setValue(props.name, item);
                      props.form.clearErrors(props.name);
                      props.closePopup(false);
                    }}
                    aria-hidden={true}
                  >
                    {props.type === "grid" && (
                      <div className={styles.image}>{item?.image && <img src={item.image} alt={item.name} />}</div>
                    )}

                    <p>{item.name}</p>
                  </div>
                );
              })}
            </div>
          }
          {props.name === "mealType" ? (
            !props.form.watch("region") && <InfoMessage text={GlobalStrings["Please select a region"]} />
          ) : props.name === "label" && !props.form.watch("product") ? (
            <InfoMessage text={GlobalStrings["Please select a product"]} />
          ) : (
            !filteredOptions?.length && <InfoMessage text={GlobalStrings["No options"]} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterPopupContent;
