import React, {useEffect} from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import {useOktaAuth} from "@okta/okta-react";
import { getFromLocalStorage } from "utils/globalFunctions";
const RequireAuth = () => {
  const location = useLocation();
  const { authState, oktaAuth } = useOktaAuth();
  const user = getFromLocalStorage("user");
  useEffect(() => {
  }, [authState, oktaAuth]);

  let content = <></>;
  if (authState || user) {
    content = <Outlet />;
  } else {
    content = <Navigate to="/" state={{ from: location }} replace />;
  }

  return content;
};
export default RequireAuth;
