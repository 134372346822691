import axios, { AxiosRequestConfig, Method } from "axios";
import { useSnackbar } from "notistack";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useOktaAuth} from "@okta/okta-react";
import { deleteFromLocalStorage, getFromLocalStorage } from "utils/globalFunctions";

interface HookProps extends AxiosRequestConfig {
  method: Method;
  url?: string;
  queryParams?: object;
  body?: any;
  local?: boolean;
  onSuccessMessage?: string;
  hideErrorMessage?: boolean;
  onErrorMessage?: string;
  onSuccess?: (data: any) => void;
  onError?: (error: any, data: any) => void;
}

interface RequestProps {
  url?: string;
  queryParams?: object;
  body?: any;
}

const useRequest = (hookProps: HookProps)=> {
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const abortController = new AbortController();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(()=> {
  }, [authState, oktaAuth]);

  const request = async (props?: RequestProps)=> {
    setPending(true);
    const accessToken = oktaAuth.getAccessToken();
    const user =getFromLocalStorage("user");
    try {
      const response = await axios({
        baseURL: hookProps.local ? "" : process.env.REACT_APP_API_URL,
        url: props?.url ?? hookProps.url,
        params: props?.queryParams ?? hookProps.queryParams,
        method: hookProps.method,
        data: props?.body || hookProps.body,
        headers:{
          Authorization: user ? `Bearer auth ${getFromLocalStorage("user")?.acccesToken}` : `Bearer okta ${accessToken}`,
          "Content-Type": hookProps.headers?.["Content-Type"] || "application/json",
        },
        signal: abortController.signal,
      });
      hookProps?.onSuccess && hookProps?.onSuccess(response);
      hookProps.onSuccessMessage &&
        enqueueSnackbar(hookProps.onSuccessMessage, {
          variant: "success",
        });
    } catch (error: any) {
      error.response?.status !== 401 &&
        error.code !== "ERR_CANCELED" &&
        hookProps.onError &&
        hookProps.onError(error, error.response?.data);
      if (error.response?.status === 401) {
        deleteFromLocalStorage("user");
        navigate("/");
      }
      if (error.code !== "ERR_CANCELED") {
        enqueueSnackbar(error?.response?.data?.message ?? error?.response?.data?.detail ?? "Something went wrong", {
          variant: "error",
        });
      }

      setPending(false);
      return false;
    }

    setPending(false);
    return true;
  };

  return { request, pending, abortController };
};

export default useRequest;
