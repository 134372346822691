import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import { Inputs } from "utils/types";
import { SubmitHandler, useForm } from "react-hook-form";
import GlobalStrings from "utils/globalStrings";

import useRequest from "hooks/useRequest";
import { AnimatedButton, Filters, Loader, PromptInput } from "components";
import { AI } from "utils/enums";
import { Filters as FiltersType } from "components/RightSidebar/RightSidebar";
import { data } from "utils/data";

type RequestBody = {
  product_type?: string;
  meal_type?: string;
  label?: string;
  img_file_path?: string;
  render: boolean;
};
interface DataExport {
  product_name: string
  region_name: string
  product_label: string
}
const Sidebar = (props: {
  setImageURL: React.Dispatch<React.SetStateAction<string>>;
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  dataExport: DataExport;
  setDataExport: React.Dispatch<React.SetStateAction<DataExport>>;
}) => {
  const form = useForm<Inputs>({
    defaultValues: {
      enableDroplets: false,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const [imagePath, setImagePath] = useState("");
  const [stabilityPromptData, setStabilityPromptData] = useState<{
    sdxl_postfix: string;
    sdxl_negative_prompt: string;
  }>();

  const { request: generateImage, pending: generateImagePending } = useRequest({
    url: "/omniverse/generate-image",
    method: "post",
    onSuccess: (response) => {
      const body: RequestBody = {
        render: false,
        product_type: watch("product")?.value,
        meal_type: watch("mealType")?.value ?? "",
        label: watch("label")?.value || "",
        img_file_path: response.data[0] ?? "",
      };
      setImagePath(response.data[0]);
      props.setImageURL(response.data[0]);
      updateAndRender({ body });
      props.setDataExport({
        ...props.dataExport,
        region_name: watch("region")?.value ?? "",
        product_name: watch("product")?.value ?? "",
        product_label: watch("label")?.value ?? "",
      });

      const selectedMealData = watch("map");
      if (selectedMealData) {
        const newAngleFilters = data.defaultValues.cameraSettings.angleFilters;

        if (selectedMealData.high_dense_color_map && selectedMealData.high_dense_zdepth_map) {
          const highDenseAngleFilterIndex = newAngleFilters.findIndex((filter) => filter.value === GlobalStrings.High);
          if (highDenseAngleFilterIndex > -1) {
            newAngleFilters[highDenseAngleFilterIndex].enabled = true;
          }
        }

        if (selectedMealData.low_dense_color_map && selectedMealData.low_dense_zdepth_map) {
          const lowDenseAngleFilterIndex = newAngleFilters.findIndex((filter) => filter.value === GlobalStrings.Low);
          if (lowDenseAngleFilterIndex > -1) {
            newAngleFilters[lowDenseAngleFilterIndex].enabled = true;
          }
        }
        props.setFilters((prev: FiltersType) => {
          return {
            ...prev,
            cameraSettings: {
              ...prev.cameraSettings,
              angleFilters: newAngleFilters,
            },
          };
        });
      }
    },
  });

  const {
    request: updateAndRender,
    pending: updateAndRenderPending,
    abortController,
  } = useRequest({
    url: "/omniverse/update-and-render",
    method: "post",
  });

  useEffect(() => {
    register("region");
    register("label");
    register("product");
    register("mealType", {
      required: GlobalStrings["Required field"],
    });
    register("AIType", {
      required: GlobalStrings["Required field"],
    });
    register("map");
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    let prompt = "";
    prompt = data.prompt;
    if (watch("AIType")?.value === AI.STABILITY) {
      prompt = `${prompt} ${stabilityPromptData?.sdxl_postfix}`;
    }

    const body: any = {
      prompt,
      negative_prompt: data.negativePrompt,
      ai_type: data.AIType.value,
      checkpoint: "realvisxlV20_v20Bakedvae.safetensors [74dda471cc]",
      sdxl_detailer: data.secondPrompt && data.secondPrompt.length > 0 ? data.secondPrompt : data.map.sdxl_detailer,
      sdxl_detector: data.map.sdxl_detector,
      sdxl_detector_threshold: data.map.sdxl_detector_threshold,
    };

    const cameraSettings = {
      Low: "low",
      Medium: "mid",
      High: "high",
    };

    if (
      // @ts-ignore
      data.map[
        // @ts-ignore
        `${cameraSettings[props.filters.cameraSettings.angle]}_${props.filters.dressing.toLowerCase()}_zdepth_map`
      ]
    ) {
      body.zdepth_map =
        // @ts-ignore
        data.map[
          // @ts-ignore
          `${cameraSettings[props.filters.cameraSettings.angle]}_${props.filters.dressing.toLowerCase()}_zdepth_map`
        ];
    } else {
      body.zdepth_map = data.map.zdepth_map;
    }
    if (
      // @ts-ignore
      data.map[
        // @ts-ignore
        `${cameraSettings[props.filters.cameraSettings.angle]}_${props.filters.dressing.toLowerCase()}_color_map`
      ]
    ) {
      // @ts-ignore
      body.color_map =
        // @ts-ignore
        data.map[
          // @ts-ignore
          `${cameraSettings[props.filters.cameraSettings.angle]}_${props.filters.dressing.toLowerCase()}_color_map`
        ];
    } else {
      body.color_map = data.map.color_map;
    }
    generateImage({ body });
  };

  useEffect(() => {
    if (watch("product") && imagePath) {
      const body: RequestBody = {
        render: false,
        product_type: watch("product")?.value,
        meal_type: watch("mealType")?.value ?? "",
        label: watch("label")?.value || "",
        img_file_path: imagePath,
      };

      updateAndRender({ body });
    }

    return () => abortController.abort();
  }, [watch("product"), watch("label")]);

  useEffect(() => {
    setValue("label", undefined);
  }, [watch("product")]);

  return (
    <div className={styles.sidebar}>
      <form>
        <Filters form={form} setStabilityPromptData={setStabilityPromptData} />
        <div className={styles.prompts_container}>
          <PromptInput
            form={form}
            name="prompt"
            label={GlobalStrings.Prompt}
            placeholder={GlobalStrings["Describe what you want to see"]}
            required
          />

          <PromptInput
            form={form}
            name="secondPrompt"
            label={GlobalStrings.Emphasis}
            placeholder={GlobalStrings["Describe the specific features of your visual"]}
          />

          <PromptInput
            form={form}
            name="negativePrompt"
            label={GlobalStrings["Negative Prompt"]}
            placeholder={GlobalStrings["Describe what you do not want to see"]}
          />
        </div>
      </form>

      <AnimatedButton onSubmit={handleSubmit(onSubmit)} errors={errors} />

      {(generateImagePending || updateAndRenderPending) && <Loader />}
    </div>
  );
};

export default Sidebar;
