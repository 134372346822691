import React, { useEffect, useState } from "react";
import axios, {AxiosInstance} from 'axios'
import styles from "./RightSidebar.module.scss";
import cameraSettingsIcon from "assets/icons/camera_settings_icon.svg";
import lightSettingsIcon from "assets/icons/light_settings_icon.svg";
import dropletsIcon from "assets/icons/droplets_icon.svg";
import exportIcon from "assets/icons/export_icon.svg";
import { ReactSVG } from "react-svg";
import TooltipPopup from "components/TooltipPopup/TooltipPopup";
import GlobalStrings from "utils/globalStrings";
import Popup from "components/Popup/Popup";
import CameraSettings from "components/CameraSettings/CameraSettings";
import LightSettings from "components/LightSettings/LightSettings";
import useRequest from "hooks/useRequest";
import Loader from "components/Loader/Loader";
import classNames from "classnames";
import {useOktaAuth} from "@okta/okta-react";
import { saveAs } from 'file-saver';
import {getFromLocalStorage} from "../../utils/globalFunctions";

export type Filters = {
  dressing: string;
  cameraSettings: {
    angle: string;
    angleFilters : {id:number, value: string, image:string, enabled:boolean}[],
    defocusDistance: number;
    fstop: number;
    HDRIRotation: number;
  };
  lightSettings: {
    keyLightExposure: number;
    HDRILightExposure: number;
    keyLight: number;
    HDRILighting: number;
    keyLightHeight: number;
    keyLightRotation: number;
    HDRI: any;
  };
  droplets: boolean;
};
export interface ErrorResponse {
  message: string
  statusCode: number
}
interface AxiosError {
  response: {
    data: ErrorResponse
  }
}
interface DataExport {
  product_name: string
  region_name: string
  product_label: string
}
const RightSidebar = (props: {
  imageURL: string;
  setImageURL: React.Dispatch<React.SetStateAction<string>>;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  dataExport: DataExport;
}) => {
  const [isOpen, setIsOpen] = useState<{
    dressing: boolean;
    cameraSettings: boolean;
    lightSettings: boolean;
  }>({
    dressing: false,
    cameraSettings: false,
    lightSettings: false,
  });
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const [dropletsEnabled, setDropletsEnabled] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const user =getFromLocalStorage("user");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const { pending: dressingPending } = useRequest({
    url: "/omniverse/setdressing-controls/set-dressing",
    method: "post",
  });

  const { request: setCameraAngle, pending: cameraAnglePending } = useRequest({
    url: "/omniverse/camera-controls/set-camera-angle",
    method: "post",
  });

  const { request: setCameraDefocus, pending: cameraDefocusPending } = useRequest({
    url: "/omniverse/camera-controls/set-camera-defocus",
    method: "post",
  });

  const { request: setCameraHDRIRotation, pending: cameraHDRIRotationPending } = useRequest({
    url: "/omniverse/hdri/set-hdr-rotation",
    method: "post",
  });

  const { request: setExposure, pending: exposurePending } = useRequest({
    url: "/omniverse/light-controls/set-light-exposure",
    method: "post",
  });

  const { request: setTemperature, pending: temperaturePending } = useRequest({
    url: "/omniverse/light-controls/set-light-temperature",
    method: "post",
  });

  const { request: setLightAngle, pending: lightAnglePending } = useRequest({
    url: "/omniverse/light-controls/pivot-light-rig",
    method: "post",
  });

  const { request: enableDroplets, pending: dropletsPending } = useRequest({
    url: "/omniverse/scene-controls/toggle-droplets",
    method: "post",
  });

  const pending =
    dressingPending ||
    cameraAnglePending ||
    cameraDefocusPending ||
    cameraHDRIRotationPending ||
    dropletsPending ||
    exposurePending ||
    lightAnglePending ||
    temperaturePending;

  useEffect(() => {
    if (props.filters?.cameraSettings?.angle) {
      const body = {
        angle: props.filters?.cameraSettings.angle.toLowerCase(),
      };
      setCameraAngle({ body });
    }
  }, [props.filters?.cameraSettings?.angle]);

  useEffect(() => {
    if (
      props.filters?.cameraSettings?.defocusDistance >= 0 &&
      props.filters?.cameraSettings?.fstop >= 0
    ) {
      const body = {
        dof: true,
        distance: +props.filters.cameraSettings.defocusDistance,
        fstop: +props.filters.cameraSettings.fstop,
      };
      setCameraDefocus({ body });
    }
  }, [props.filters?.cameraSettings?.defocusDistance, props.filters?.cameraSettings?.fstop]);

  useEffect(() => {
    if (props.filters?.cameraSettings?.HDRIRotation >= 0) {
      const body = {
        y_rotation: +props.filters.cameraSettings.HDRIRotation,
      };
      setCameraHDRIRotation({ body });
    }
  }, [props.filters?.cameraSettings?.HDRIRotation]);

  useEffect(() => {
    if (
      (props.filters?.lightSettings?.HDRILightExposure >= 0 || props.filters?.lightSettings?.keyLightExposure >= 0) 
    ) {
      const body = {
        key_exposure: props.filters?.lightSettings?.keyLightExposure,
        hdr_exposure: props.filters?.lightSettings?.HDRILightExposure,
      };
      setExposure({ body });
    }
  }, [props.filters?.lightSettings?.keyLightExposure, props.filters?.lightSettings?.HDRILightExposure]);

  useEffect(() => {
    if (
      (props.filters?.lightSettings?.keyLight >= 0 || props.filters?.lightSettings?.HDRILighting >= 0)
    ) {
      const body = {
        key_temperature: props.filters?.lightSettings?.keyLight,
        hdr_temperature: props.filters?.lightSettings?.HDRILighting,
      };
      setTemperature({ body });
    }
  }, [props.filters?.lightSettings?.keyLight, props.filters?.lightSettings?.HDRILighting]);

  useEffect(() => {
    if (
      (props.filters?.lightSettings?.keyLightHeight >= 0 || props.filters?.lightSettings?.keyLightRotation >= 0) 
    ) {
      const body = {
        y_rotation: props.filters?.lightSettings?.keyLightRotation,
        height: props.filters?.lightSettings?.keyLightHeight,
      };
      setLightAngle({ body });
    }
  }, [props.filters?.lightSettings?.keyLightHeight, props.filters?.lightSettings?.keyLightRotation]);

  const dropletsHandler = () => {
    setDropletsEnabled((prev) => !prev);
    const body = {
      toggle: !dropletsEnabled,
    };
    enableDroplets({ body });
  };

  const menuItems = [
    {
      id: 1,
      name: "cameraSettings",
      label: GlobalStrings["Camera Settings"],
      image: cameraSettingsIcon,
      component: (
        <CameraSettings
          filters={props.filters}
          setFilters={props.setFilters}
          setIsOpen={(value: boolean) => popupHandler("cameraSettings", value)}
        />
      ),
      tooltipMessage:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      id: 2,
      name: "lightSettings",
      label: GlobalStrings["Light Settings"],
      image: lightSettingsIcon,
      component: (
        <LightSettings
          filters={props.filters}
          setFilters={props.setFilters}
          setIsOpen={(value: boolean) => popupHandler("lightSettings", value)}
        />
      ),
      tooltipMessage:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      id: 3,
      label: GlobalStrings.Droplets,
      image: dropletsIcon,
      onClick: () => dropletsHandler(),
      isEnabled: dropletsEnabled,
    },
  ];

  const popupHandler = (name: any, value: any) => {
    setIsOpen({
      dressing: false,
      cameraSettings: false,
      lightSettings: false,
      [name]: value,
    });
  };

 const axiosInstance: AxiosInstance = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: user ? `Bearer auth ${getFromLocalStorage("user")?.acccesToken}` : `Bearer okta ${accessToken}`,
    },
  });

  const captureLowResRequest = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const segments = props.imageURL.split('/');
      const midgroundName = segments[segments.length - 1]
      const bodyRequest = {
        midground_name: midgroundName,
        region_name: props.dataExport.region_name,
        product_name:props.dataExport.product_name,
        product_label:props.dataExport.product_label
      }
      const responseData = await axiosInstance.post('/omniverse/capture-low-res', bodyRequest, { responseType: 'arraybuffer' }); 
      const blob = new Blob([responseData.data], { type: 'application/zip' });
      const fileName = midgroundName.split('.')[0];
      saveAs(blob, `${fileName}.zip`);
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError
    } finally {
      setIsLoading(false);
    }
  };
const upscaleImageRequest = async (): Promise<string> => {
  try {
    const bodyRequest = {
      image_path:props.imageURL
    };
    const responseData = await axiosInstance.post('/omniverse/upscale-image', bodyRequest);
    return responseData.data.output_path;
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error(axiosError);
    throw axiosError;
  }
};
const captureHighResRequest = async (outputPath: string): Promise<void> => {
  try {
    const segments = props.imageURL.split('/');
    const midgroundName = segments[segments.length - 1]
    const bodyRequest = {
      highres_backplate_path: outputPath,
      midground_name: midgroundName,
      region_name: props.dataExport.region_name,
      product_name:props.dataExport.product_name,
      product_label:props.dataExport.product_label
    };
    const responseData = await axiosInstance.post('/omniverse/capture-high-res', bodyRequest, { responseType: 'arraybuffer' });
    const blob = new Blob([responseData.data], { type: 'application/zip' });
    const fileName = midgroundName.split('.')[0];
    saveAs(blob, `${fileName}.zip`);
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error(axiosError);
    throw axiosError;
  }
};
const handleHighResRequest = async (): Promise<void> => {
  setIsLoading(true);
  try {
    setLoadingMessage(GlobalStrings['We are busy upscaling your image, this will take approx 1-2 minutes. Please don\'t close the window!']);
    const outputPath = await upscaleImageRequest();
    setLoadingMessage(GlobalStrings['Downloaded image(s) will appear in your downloads folder when complete.']);
    await captureHighResRequest(outputPath);
  } catch (error) {
    console.error(error);
  } finally {
    setIsLoading(false);
    setLoadingMessage('')
  }
};
  async function downloadImage(resolution: string) {
    try {
      if(resolution === 'low'){
        await captureLowResRequest();
      } else if(resolution === 'high'){
        await handleHighResRequest();
      }
    } catch (err) {
      const downloadImageError = err as ErrorResponse;
      const message = downloadImageError.message
      console.log(message)
    }
  }
  const handleExportPopup = (resolution: string) => {
    downloadImage(resolution); 
    setExportModalIsOpen(false);
  };
  useEffect(() => {

  }, [authState]);


  return (
    <div
      className={classNames(styles.right_sidebar, {
        [styles.active]: true,
      })}
    >
      <p>{GlobalStrings.Tools}</p>
      <div className={styles.menu_items}>
        {menuItems.map((item) => {
          return item.component ? (
            <TooltipPopup
              key={item.id}
              headerTitle={item.label}
              position="left top"
              isOpen={isOpen[item.name as "dressing" | "cameraSettings" | "lightSettings"]}
              setIsOpen={(value) => popupHandler(item.name, value)}
              content={item.component}
              tooltipMessage={item?.tooltipMessage}
              trigger={
                <div key={item.id} className={styles.menu_item}>
                  <ReactSVG src={item.image} className={styles.image} />
                  <p>{item.label}</p>
                </div>
              }
            />
          ) : (
            <div
              key={item.id}
              className={classNames(styles.menu_item, {
                [styles.active]: item.isEnabled,
              })}
              onClick={item.onClick}
              aria-hidden="true"
            >
              <ReactSVG src={item.image} className={styles.image} />
              <p>{item.label}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.separator}></div>
      <div className={styles.menu_item} onClick={() => setExportModalIsOpen(true)} aria-hidden="true">
        <ReactSVG src={exportIcon} className={styles.image} />
        <p>{GlobalStrings.Export}</p>
      </div>
      {exportModalIsOpen && (
        <Popup setModalIsOpen={setExportModalIsOpen}>
          <div className={styles.share_modal}>
            <p>{GlobalStrings["Are you sure you want to download your image?"]}</p>
            <div className={styles.buttons}>
              <button onClick={() => handleExportPopup('low')}>{GlobalStrings['Low Resolution']}</button>
              <button onClick={() => handleExportPopup('high')}>{GlobalStrings['High Resolution']}</button>
            </div>
          </div>
        </Popup>
      )}
      {isLoading ? <Loader message={loadingMessage} /> : <></>}
      {pending  && <Loader />}
    </div>
  );
};

export default RightSidebar;