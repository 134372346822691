import GlobalStrings from "./globalStrings";
import overHeadImage from "assets/icons/cameraAngle/over_head.png";
import flatLayImage from "assets/icons/cameraAngle/flat_lay.png";
import angleImage from "assets/icons/cameraAngle/45_degree.png";

export const data = {
  defaultValues: {
    dressing: "Dense",
    cameraSettings: {
      angle: "Low",
      angleFilters : [
        {
          id: 1,
          value: GlobalStrings.Low,
          image: overHeadImage,
          enabled:true, 
        },
        {
          id: 2,
          value: GlobalStrings.Medium,
          image: flatLayImage,
          enabled:false, 
        },
        {
          id: 3,
          value: GlobalStrings.High,
          image: angleImage,
          enabled:false, 
        },
      ],
      defocusDistance: 75,
      fstop: 22,
      HDRIRotation: 22,
    },
    lightSettings: {
      temperature: 0,
      keyExposure: 1000,
      HDRIExposure: 1000,
      keyLightHeight: 50,
      keyLightRotation: 130,
      HDRI: 50,
      dofDistance: 46,
      dofStop: 22,
      keyLight: 6500,
      HDRILighting: 6500,
    },
    droplets: false,
  },
};
