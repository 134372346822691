export const setToLocalStorage = (key: string, value: string | Object) => {
  if (!key || typeof key !== "string") {
    throw new Error("Invalid key");
  }

  if (typeof value === "object") {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const getFromLocalStorage = (key: string) => {
  if (!key || typeof key !== "string") {
    throw new Error("Invalid key");
  }

  try {
    return JSON.parse(localStorage.getItem(key) ?? "");
  } catch {
    return localStorage.getItem(key);
  }
};

export const deleteFromLocalStorage = (key: string) => {
  if (!key || typeof key !== "string") {
    throw new Error("Invalid key");
  }

  localStorage.removeItem(key);
};
