import React from "react";
import styles from "./SearchInput.module.scss";
import GlobalStrings from "utils/globalStrings";

const SearchInput = (props: {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  disabled?: boolean;
}) => {
  return (
    <div className={styles.search_container}>
      <form noValidate autoComplete="new-password">
        <input
          type="text"
          name="searchInput"
          placeholder={GlobalStrings.Search}
          onChange={(e) => props.setSearchValue(e.target.value)}
          value={props.searchValue}
          autoComplete="new-password"
          disabled={props.disabled}
        />
      </form>
    </div>
  );
};

export default SearchInput;
