import React from "react";
import styles from "./PromptInput.module.scss";
import GlobalStrings from "utils/globalStrings";
import { UseFormReturn } from "react-hook-form";
import { Inputs } from "utils/types";
import classNames from "classnames";

const promptRegex = /^[\w\s.,;|'"`~!@#$%^&*()+=<>?/{}[\]]*$/;

const PromptInput = ({
  form,
  label,
  placeholder,
  name,
  required,
}: {
  form: UseFormReturn<Inputs, any, any>;
  label: string;
  placeholder: string;
  name: "prompt" | "secondPrompt" | "negativePrompt";
  required?: boolean;
}) => {
  return (
    <div className={styles.form_group}>
      <label htmlFor={name}>{label}</label>
      <textarea
        {...form.register(name, {
          required: {
            value: required ?? false,
            message: GlobalStrings["Required field"],
          },
          pattern: {
            value: promptRegex,
            message: GlobalStrings.promptsValidation,
          },
          maxLength: {
            value: 2000,
            message: GlobalStrings.maxLength2000,
          },
        })}
        placeholder={placeholder}
        className={classNames({ invalid: form.formState.errors?.[name]?.message })}
      />
      <div className={styles.prompt_container}>
        <p className={styles.counter}>
          {form.watch(name)?.length || 0}
          {GlobalStrings["of 2000 Characters"]}
        </p>
        {form.formState.errors[name] && <p className={styles.error}>{form.formState.errors?.[name]?.message}</p>}
      </div>
    </div>
  );
};

export default PromptInput;
