import React, { useEffect, useState } from "react";
import styles from "./SelectWithoutForm.module.scss";
import { SelectOption } from "utils/types";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import downIcon from "assets/icons/down-icon.svg";
import GlobalStrings from "utils/globalStrings";

const SelectWithoutForm = (props: {
  label?: string;
  options: SelectOption[];
  disabled?: boolean;
  value: any;
  setValue: any;
}) => {
  const [selectedValue, setSelectedValue] = useState<SelectOption>();

  useEffect(() => {
    const value = props.options.find((item) => item.value === props.value);
    setSelectedValue(value);
  }, [props.value]);

  const [isExtended, setIsExtended] = useState(false);

  return (
    <div className={styles.select_container}>
      <div
        className={classNames(styles.selected_value, {
          [styles.disabled]: props.disabled,
        })}
        onClick={() => (!props.disabled ? setIsExtended((prev) => !prev) : undefined)}
        aria-hidden="true"
      >
        <div className={styles.text}>
          {selectedValue?.image && (
            <div className={styles.image_container}>
              <img src={selectedValue.image} alt={selectedValue.label} />
            </div>
          )}
          <p>{!selectedValue?.value ? props.label : selectedValue.label}</p>
        </div>

        <div
          className={classNames(styles.icon, {
            [styles.active]: isExtended,
          })}
        >
          <ReactSVG src={downIcon} className={styles.image} />
        </div>
      </div>
      <div className={styles.options_container}>
        <ul
          className={classNames(styles.options, {
            [styles.active]: isExtended,
          })}
        >
          {props.options?.map((item) => {
            return (
              <li
                key={item.value}
                onClick={() => {
                  props.setValue(item);
                  setIsExtended(false);
                }}
                className={classNames(styles.option, {
                  [styles.active]: props.value === item.value,
                })}
                aria-hidden="true"
              >
                {item.image && (
                  <div className={styles.image_container}>
                    <img src={item.image} alt={item.label} />
                  </div>
                )}
                <p>{item.label}</p>
              </li>
            );
          })}
          {!props.options?.length && (
            <li className={classNames(styles.option)}>
              <p className={styles.no_values}>{GlobalStrings["No values"]}</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SelectWithoutForm;
